import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '@cmp/site/Footer'
import NavBar from '@cmp/site/NavBar'
import MobileMenu from '@cmp/site/MobileMenu'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import BaseLayout from '../Base'
import Modal from '@cmp/sections/Modal'
import styles from '../MainLayout.module.scss'
import { SEO } from '@islandsstofa/react'
import useGetSeoProperties from '@root/src/hooks/useGetSeoProperties'
import favicon from '@root/static/favicon.ico'

const MainLayout: React.FC = ({ children }) => {
  const { t } = useLanguage()

  return (
    <BaseLayout>
      <Meta />

      <a href='#main-content' className={styles.skipToMain}>
        {t('jump to content')}
      </a>

      <Modal />
      <main id='main-content' className={styles.main}>
        <NavBar />
        <MobileMenu />
        {children}
        <Footer />
      </main>
    </BaseLayout>
  )
}

const Meta = () => {
  const { language } = useLanguage()

  const meta = useGetSeoProperties()

  return (
    <>
      <Helmet>
        <title>Grænvangur</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link data-react-helmet='true' rel='icon' href={favicon} />

        <html lang={language} />
      </Helmet>
      <SEO {...meta} />
    </>
  )
}

export default MainLayout
