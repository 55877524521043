import React from 'react'
import { handleImgixUrl } from '@kogk/common'
import { EmployeeType } from '@islandsstofa/react'

const StaffModal: React.FC<{ content: EmployeeType }> = ({ content }) => {
  return (
    <div className='d-flex w-100 flex-column flex-lg-row p-lg-2'>
      <img
        className='modal-template__image-box col-lg-6'
        src={handleImgixUrl(content.image.url).url()}
        alt='employee'
      />
      <div className='col-lg-6 pt-2 pt-lg-5 pl-lg-3'>
        <div>
          <h4>{content.full_name}</h4>
          <div
            dangerouslySetInnerHTML={{ __html: content.bio.html }}
            className='article mb-3'
          />
        </div>
      </div>
    </div>
  )
}

export default StaffModal
