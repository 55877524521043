import React from 'react'
import { Container, handleImgixUrl } from '@kogk/common'
import Img from 'gatsby-image'
import { AspectRatioBox, GalleryImageType } from '@islandsstofa/react'

const ImageGallery: React.FC<{
  content: GalleryImageType
}> = ({ content }) => {
  return (
    <div className='modal-template__add-some-pad mt-4 d-flex flex-column w-100'>
      <Container>
        <h4 className='modal-template__gallery-image-title'>
          {content.image_title}
        </h4>
        <AspectRatioBox ratio='widescreen'>
          <Img
            fluid={handleImgixUrl(content.gallery_image.url).gatsbyImageFluid()}
            alt={content.image_title || 'content image'}
          />
        </AspectRatioBox>
        <p className='caption'>{content.image_caption}</p>
      </Container>
    </div>
  )
}

export default ImageGallery
