import React from 'react'
import { useGetFooter } from '@src/hooks'
import { FooterUi, ButtonDep as Button, IconType } from '@islandsstofa/react'

const Postlist = () => (
  <a
    className='footer__link-text'
    href='http://frettabref.green.is'
    target='_blank'
  >
    <Button
      className='d-flex '
      type='secondary'
      icon='arrowRight'
      label='Skráðu þig á póstlistann'
    />
  </a>
)

const Footer = () => {
  const props: { logoType: IconType; [propName: string]: any; en?: boolean } = {
    logoType: 'graenvangurLogo',
    ...useGetFooter(),
    onPostlistRender: () => <Postlist />
  }
  return <FooterUi {...props} />
}

export default Footer
