import React, { useContext } from 'react'
import { Container, Row, Col, Sticky } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { useGlobalData } from '@src/data/global-data'
import cn from 'classnames'
import Burger from '@cmp/site/NewBurger'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'
import { Icon, IconButton, useModal, MenuItemType } from '@islandsstofa/react'
import NavItem from '@cmp/site/NavItem'

const Logo = () => {
  const { close: closeMobileMenu } = useContext(MobileMenuContext)
  return (
    <Link to='/' onClick={() => closeMobileMenu()} aria-label='Logo'>
      <Icon containerClass='d-none d-lg-block logo' type='graenvangurLogo' />
      <Icon containerClass='d-block d-lg-none logo' type='graenvangurLogo' />
    </Link>
  )
}

const Navigation: React.FC<{
  items: MenuItemType[]
  containerClass: string
}> = ({ items, containerClass }) => {
  return (
    <div className={cn(containerClass)}>
      {items
        .filter(item => item)
        .map((item, idx) => (
          <NavItem key={idx} item={item} />
        ))}
    </div>
  )
}

const LanguageAndSearch = () => {
  const { executeModal } = useModal()
  const runModal = () => {
    executeModal([], 0, 'modal__search', 'Search')
  }

  return (
    <div className='d-flex'>
      <IconButton onClick={() => runModal()} type='search'></IconButton>
    </div>
  )
}

const NavBar = () => {
  const { mainMenu } = useGlobalData()
  return (
    <Sticky initialHeight={88}>
      <div className='bg-white'>
        <Container>
          <Row className='align-items-center'>
            <nav className='navbar'>
              <Col col={1}>
                <Logo />
              </Col>
              <>
                <Navigation
                  containerClass='w-100 justify-content-center d-none d-lg-flex'
                  items={mainMenu}
                />
                <Col className='d-none d-lg-flex' col={1}>
                  <LanguageAndSearch />
                </Col>
              </>
              <Col className='d-flex d-lg-none'>
                <Burger />
              </Col>
            </nav>
          </Row>
        </Container>
      </div>
    </Sticky>
  )
}

export default NavBar
